// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blogs-js": () => import("../src/templates/blogs.js" /* webpackChunkName: "component---src-templates-blogs-js" */),
  "component---src-templates-tags-js": () => import("../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-blog-post-js": () => import("../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-algorithms-js": () => import("../src/pages/algorithms.js" /* webpackChunkName: "component---src-pages-algorithms-js" */),
  "component---src-pages-algorithms-bubble-sort-js": () => import("../src/pages/algorithms/bubble-sort.js" /* webpackChunkName: "component---src-pages-algorithms-bubble-sort-js" */),
  "component---src-pages-algorithms-flood-fill-js": () => import("../src/pages/algorithms/flood-fill.js" /* webpackChunkName: "component---src-pages-algorithms-flood-fill-js" */),
  "component---src-pages-algorithms-permutation-js": () => import("../src/pages/algorithms/permutation.js" /* webpackChunkName: "component---src-pages-algorithms-permutation-js" */),
  "component---src-pages-algorithms-quick-sort-js": () => import("../src/pages/algorithms/quick-sort.js" /* webpackChunkName: "component---src-pages-algorithms-quick-sort-js" */),
  "component---src-pages-games-js": () => import("../src/pages/games.js" /* webpackChunkName: "component---src-pages-games-js" */),
  "component---src-pages-games-mine-sweeper-js": () => import("../src/pages/games/mine-sweeper.js" /* webpackChunkName: "component---src-pages-games-mine-sweeper-js" */),
  "component---src-pages-games-tetris-js": () => import("../src/pages/games/tetris.js" /* webpackChunkName: "component---src-pages-games-tetris-js" */),
  "component---src-pages-games-tic-tac-toe-js": () => import("../src/pages/games/tic-tac-toe.js" /* webpackChunkName: "component---src-pages-games-tic-tac-toe-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-misc-js": () => import("../src/pages/misc.js" /* webpackChunkName: "component---src-pages-misc-js" */),
  "component---src-pages-misc-music-maker-js": () => import("../src/pages/misc/music-maker.js" /* webpackChunkName: "component---src-pages-misc-music-maker-js" */),
  "component---src-pages-projects-js": () => import("../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */)
}

